<template>
  <div class="types">    <!-- banner -->
    <div class="types-banner banner">
      <h1 class="banner-title">分类</h1>
    </div>
    <!--中间内容-->
    <div class="m-container-small m-types">
      <div class="ui container">
        <!--header-->
        <div class="ui top attached segment my-shadow">
          <div class="ui middle aligned two column grid">
            <div class="column">
              <h3 class="ui my-blue header">分类</h3>
            </div>
            <div class="right aligned column">
              共 <h2 class="ui orange header m-inline-block m-text-thin"> {{typeList.length}} </h2> 个
            </div>
          </div>
        </div>

        <div class="ui attached segment m-padded-tb-large my-shadow">
          <template v-for="(item , index) in typeList">
            <div class="ui labeled button m-margin-tb-tiny" @click="listBlogsByCurrentType( item.id )" :key="item.id" style="margin-left: 10px">
              <a class="ui blue basic button"><span class="blackFont">{{item.typeName}}</span></a>
              <div class="ui basic blue left pointing label"> {{index + 1}} </div>
            </div>
          </template>
        </div>

        <!--header-->
        <div class="ui top attached segment my-shadow">
          <div class="ui middle aligned two column grid">
            <div class="column">
              <h3 class="ui my-blue header">博客</h3>
            </div>
            <div class="right aligned column">
              共 <h2 class="ui orange header m-inline-block m-text-thin">{{pagination.total}}</h2> 篇
            </div>
          </div>
        </div>

        <!--博客数据-->
        <div class="ui attached segment my-blog-shadow">

          <div class="ui padded vertical segment m-padded-tb-large" v-for="item in blogList" :key="item.id">
            <div class="ui middle aligned mobile reversed stackable grid">
              <div class="eleven wide column" style="cursor:pointer;">
                <h3 class="ui header" @click="toBlog(item.id)">{{item.title}}</h3>
                <p class="m-text" @click="toBlog(item.id)">{{item.description}}</p>
                <div class="ui grid">
                  <div class="eleven wide column">
                    <div class="ui mini horizontal link list">
                      <div class="item">
                        <img v-bind:src=item.creator.avatar class="ui avatar image">
                        <div class="content"><a class="header">{{item.creator.nickname}}</a></div>
                      </div>
                      <div class="item">
                        <i class="calendar icon"></i> {{item.createTime}}
                      </div>
                      <div class="item">
                        <i class="eye icon"></i> {{item.views}}
                      </div>
                      <div class="item">
                        <i class="thumbs up outline icon"></i> {{item.thumbs}}
                      </div>
                    </div>
                  </div>
                  <div class="right aligned five wide column">
                    <a target="_self" class="ui my-blue basic label m-padded-tiny m-text-thin">{{item.type.typeName}}</a>
                  </div>
                </div>
              </div>
              <div class="five wide column">
                <a target="_self">
                  <img v-bind:src=item.firstPictureUrl @click="toBlog(item.id)" alt="" class="ui rounded image">
                </a>
              </div>

            </div>
          </div>
        </div>

        <!--footer-->
        <div class="ui bottom attached segment my-blog-shadow">
          <div class="pagination-container">
            <el-pagination
              class="pagiantion"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.page"
              :page-sizes="[2,4]"
              :page-size="pagination.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination.total">
            </el-pagination>
          </div>
        </div>

      </div>
    </div>

    <br>
    <br>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeTypeId: '', // 主页点击类型回调Id
      blogList: [], // 当前页要展示的博客分页列表数据
      typeList: [], // 分类列表的数据
      pagination: { // 分页相关模型数据
        page: 1, // 当前页码
        size: 2, // 每页显示的记录数
        total: 0, // 总记录数
      },
      user: {},
      nickname: '',
      // 被激活的链接地址
      avatar: ''
    }
  },
  created () {
    this.getCallbackTypeId();
    // this.getUser()
    this.getTypeList( this.activeTypeId )
    this.getBlogsByType( this.activeTypeId )
  },
  methods: {
    getCallbackTypeId() {
      // 点击类型id赋值
      this.activeTypeId = this.$route.query.typeId;
    },
    toBlog (blogId) {
      this.$router.push({
        path: "/blog",
        query: {
          blogId: blogId
        }
      })
    },
    async listBlogsByCurrentType ( currentTypeId ) {
      await this.getBlogsByType( currentTypeId )
    },
    async getTypeList ( currentTypeId ) {
      const param = {
        id: currentTypeId ? currentTypeId : null
      }
      const res = await this.$http.get('/type/listTypes', param)
      if (res.code !== this.$constant.RESPONSE_CODE.SUCCESS) {
        return this.$message.error('获取类型列表失败！')
      }
      this.typeList = res.data.list
    },

    // 查询当前类型对应博客
    async getBlogsByType (typeId) {
      // 发送ajax，提交分页请求（页码，每页显示条数，查询条件)
      const param = {
        page: this.pagination.page,
        size: this.pagination.size,
        typeIds: typeId ? [typeId].toLocaleString() : null,
        published: this.$constant.PUBLISH_STATUS.PUBLISHED.CODE
      }
      const res = await this.$http.get('/blog/listBlogs', param)
      if (res.code !== this.$constant.RESPONSE_CODE.SUCCESS) {
        this.$message.error('获取博客列表失败！')
      }
      this.pagination.total = res.data.total;
      this.blogList = res.data.list
    },

 /*   getUser () {
      this.user = window.sessionStorage.getItem('user')
      if (this.user != null) {
        this.nickname = JSON.parse(this.user).nickname
        this.avatar = JSON.parse(this.user).avatar
      }
    },*/
    logout () {

    },
    handleCurrentChange (page) {
      // 设置最新的页码
      this.pagination.page = page
      // 重新调用getBlogsByType方法进行分页查询
      this.getBlogsByType(this.activeTypeId)
    },
    handleSizeChange (newSize) {
      this.pagination.size = newSize
      this.getBlogsByType(this.activeTypeId)
    }
  },
  mounted () {
    $('.ui.dropdown').dropdown({
      on: 'hover'
    })
    $('.menu.toggle').click(function () {
      $('.m-item').toggleClass('m-mobile-hide')
    })
  }
}
</script>

<style scoped>
  .container{
    animation: main 1.0s;
  }
  .m-types {
    padding-top: 69vh !important;
    padding-bottom: 0px !important;
  }
  .types-banner {
    height: 67vh;
    background: url("https://wsong.oss-cn-hangzhou.aliyuncs.com/%20song-blog/blog-main-image/%20823451900836134912type.jpg") center
    center / cover no-repeat;
    background-color: white;
  }
</style>
